import Vue from "vue";

Vue.filter('currency', function (value) {
  return parseFloat(value).toLocaleString(
    process.env.VUE_APP_LOCALE,
    {
      style: 'currency',
      currency: process.env.VUE_APP_CURRENCY
    }
  )
})