import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueTranslate from "vue-translate-plugin";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import VueRouter from "vue-router";
import VueClipboard from "vue-clipboard2";
import VueTheMask from "vue-the-mask";
import Paginate from "vuejs-paginate";
import App from "./App.vue";
import router from "./router/routes";
import store from "./state/store/index.js";
import "./state/helpers/index.js";

import "@/design/index.scss";

Vue.use(VueRouter);
Vue.use(require("vue-moment"));
Vue.use(VueTranslate);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(VueClipboard);
Vue.component("paginate", Paginate);
Vue.use(VueTheMask);

import ToastPlugin from 'vue-toast-notification';
Vue.use(ToastPlugin, {
  position: 'top-right',
  duration: 50000,
  dismissible: true,
});
import 'vue-toast-notification/dist/theme-bootstrap.css';

import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

import vSelect from 'vue-select';
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

import PrimeVue from 'primevue/config';
Vue.use(PrimeVue);
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primeicons/primeicons.css';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
