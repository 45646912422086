import { api } from "@/state/services";
import router from "@/router/routes";

const token = localStorage.getItem("token");
const user = JSON.parse(localStorage.getItem("user"));

const state = token
  ? { status: { loggedIn: true }, token, user }
  : { status: {}, token: null, user };

const actions = {
  login({ dispatch, commit }, { username, password }) {
    commit("loginRequest", { username });

    localStorage.removeItem("categories");
    localStorage.removeItem("cart");

    api
      .post("login", { username: username, password: password })
      .then((response) => {
        if (response.data.status == "success") {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          commit("loginSuccess", response.data);
          dispatch("alert/success", response.data.message, { root: true });
          router.push("/");
        } else {
          commit("loginFailure", response.data.message);
          dispatch("alert/error", response.data.message, { root: true });
        }
      });
  },
  token({ dispatch, commit }, { key }) {
    api.post("login/token", { token: key }).then((response) => {
      if (response.data.status == "success") {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        commit("loginSuccess", response.data);
        dispatch("alert/success", response.data.message, { root: true });
        router.push("/");
      } else {
        commit("loginFailure", response.data.message);
        dispatch("alert/error", response.data.message, { root: true });
      }
    });
  },
  avatar({ dispatch, commit }) {
    api.get("user").then((response) => {
      if (response.data.status == "success") {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        commit("avatar", response.data.user);
        dispatch("alert/success", response.data.message, { root: true });
      }
    });
  },
  logout({ commit }) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("categories");
    localStorage.removeItem("cart");
    commit("logout");
  },
};

const mutations = {
  loginRequest(state, token) {
    state.status = { loggingIn: true };
    state.token = token;
  },
  loginSuccess(state, data) {
    state.status = { loggedIn: true };
    state.token = data.token;
    state.user = JSON.parse(JSON.stringify(data.user));
  },
  loginFailure(state) {
    state.status = {};
    state.token = null;
  },
  logout(state) {
    state.status = {};
    state.token = null;
  },
  avatar(state, user) {
    state.user = JSON.parse(JSON.stringify(user));
  },
  registerRequest(state) {
    state.status = { registering: true };
  },
  registerSuccess(state) {
    state.status = {};
  },
  registerFailure(state) {
    state.status = {};
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
};
